import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import {
    Card,
    CardContent,
    CardHeader,
    Typography,
    Divider,
} from '@material-ui/core';

import '../styles/report.css';

import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';

const useStyles = makeStyles({
    cardTitle: {
        display: 'flex',
        justifyContent: 'space-between'
    }
});

function PowerBI(props) {
    const { refPBI, width, theme, getAccessTokenWithRefresh, API_DEFAULT, setShowBackdrop, validRole } = props;
    const [accessToken, setAccessToken] = props.accessToken;
    const [pbiEmbed, setPbiEmbed] = props.pbi;
    const classes = useStyles();

    const fetchPBIEmbedService = async (access = accessToken) => {
        setShowBackdrop(true);
        const res = await fetch(`${API_DEFAULT}/productividad/pbi_embed_token/`, {
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access }
        });
        res.json().then(async res => {
            if (res['code'] === 'token_not_valid') {
                let newAccess = await getAccessTokenWithRefresh();
                if (newAccess) {
                    setAccessToken(newAccess);
                    localStorage.setItem('access', newAccess);

                    fetchPBIEmbedService(newAccess);
                }
            }
            else {
                setPbiEmbed(res);
                setShowBackdrop(false);
            }
        });
    }

    useEffect(() => {
        if (validRole) { fetchPBIEmbedService() }
    }, [validRole]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className='PowerBI-body'>
            <Card>
                <CardHeader
                    style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }}
                    classes={{
                        title: classes.cardTitle
                    }}
                    title={
                        <Typography variant='h6'>Reporte de costos</Typography>
                    }
                />
                <Divider />
                <CardContent>
                    <div ref={refPBI} className='PowerBI-body' style={{ height: (86 / 153) * width + 5686 / 153 }}>
                        {pbiEmbed['accessToken'] !== undefined ?
                            <PowerBIEmbed
                                embedConfig={
                                    {
                                        type: 'report', // Supported types: report, dashboard, tile, visual, and qna.
                                        id: pbiEmbed['reportConfig'][0]['reportId'],
                                        embedUrl: pbiEmbed['reportConfig'][0]['embedUrl'],
                                        accessToken: pbiEmbed['accessToken'],
                                        tokenType: models.TokenType.Embed, // Use models.TokenType.Aad if you're embedding for your organization.
                                        settings: {
                                            panes: {
                                                filters: {
                                                    expanded: false,
                                                    visible: false
                                                }
                                            },
                                        }
                                    }
                                }
                                eventHandlers={
                                    new Map([
                                        ['loaded', function () {
                                            console.log('Report loaded');
                                        }],
                                        ['rendered', function () {
                                            console.log('Report rendered');
                                        }],
                                        ['error', function (event) {
                                            console.log(event.detail);
                                        }]
                                    ])
                                }
                                cssClassName={
                                    'report-style-class'
                                }
                                getEmbeddedComponent={
                                    (embeddedReport) => {
                                        window.report = embeddedReport;
                                    }
                                }
                            />
                            :
                            null
                        }
                    </div>
                </CardContent>
            </Card>
        </div>
    );
}

export default PowerBI;
